function App() {
  return (
    <div>
      <div
        class="main"
        style={{ backgroundImage: "url('images/default.jpg')" }}
      >
        <div class="cover black" data-color="black"></div>
        <div class="container">
          <h1 class="logo cursive">Saptrishi Music</h1>
          <div class="content">
            <h4 class="motto">
              We are updating our Platform.!! Sorry for Inconvinience. Will be
              back soon.!!
            </h4>
            <div class="subscribe">
              <h5 class="info-text">
                For any query, feel free to contact at{" "}
                <span style={{ fontWeight: "bolder" }}>
                  saptrishimusic@gmail.com
                </span>
              </h5>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default App;
